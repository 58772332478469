<template>
    <div class="position-relative flex justify-center"
         style="width: 100%; align-items: center"
         :style="screen_angle === 0 && !full_screen_mode ? 'height:200px' : 'height:100%'"
    >
        <div class="flex justify-center position-relative bg-live" style="overflow: hidden; align-items: center; width:100%; height: 100%"
             :style="live_info.cartl_chttg_img_url
                ? 'background-image: url(' + live_info.cartl_chttg_img_url + ');'
                : ''"
             >
            <video
                ref="videoRef"
                id="video"
                src=""
                playsinline muted autoplay

                style="width: 100% !important; height: 100% !important; object-fit: cover;"
                class="box-screen"

                @error="onVideoError"
            >
            </video>
            <div class="position-absolute full-width full-height" style="top:0; left: 0;"
                 v-if="!is_streaming"
            >
                <div class="image_null">
                    <img src="@/assets/image/ani_icon_preparing.gif" alt="image_null">
                    <p class="size-px-14 color-white font-weight-400">{{ $language.live.live_ready }}</p>
                </div>
            </div>

        </div>
        <div class="size_controller" v-if="is_streaming">
            <button style="color: white" v-if="!is_on_sound && !show_layout" @click="mutedControl">
                <img src="@/assets/image/btn_sound_off.svg" alt="sound_off">
            </button>
            <button style="color: white" v-if="is_on_sound && !show_layout" @click="mutedControl">
                <img src="@/assets/image/btn_sound_on.svg" alt="sound_on">
            </button>
        </div>
        <div class="controller_container" @click="touchLayout($event)">

        <transition name="fade">
            <div class="controller" v-if="show_layout">
                <div class="size_controller">
                    <button style="color: white" v-show="!full_screen_mode" @click="setScreenMode" id="btn">
                        <img src="@/assets/image/full_screen.svg" alt="full_screen" id="btn">
                    </button>
                    <div v-show="full_screen_mode">
                        <div class=" mr-10"
                            style="top: 2px; display: inline-block; background: rgba(0, 0, 0, 0.50); padding: 5px 10px; border-radius: 16px;">
                            <span
                                class="color-white size-px-14 mr-5 ml-5"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <circle cx="3" cy="3" r="3" fill="#DC505C"/>
                                </svg>
                                <img src="@/assets/image/icon_user.svg" alt="user">{{ live_info.participation_member_count }}</span>
                        </div>
                        <button class="white mt-auto" @click="setScreenMode">
                            <img src="@/assets/image/default_screen.svg" alt="default_screen" id="btn">
                        </button>
                    </div>
                </div>
                <div>
                    <button
                        @click="show_btn = !show_btn"
                        style="width: 40px; height: 40px; background-color: rgba(255, 255, 255, 0.2); border-radius: 100%"
                    >
                        <img :src="require('@/assets/image/set_icon.svg')" class="width-100" id="btn" style="width: 25px; height: 25px;" alt="set_icon">
                    </button>
                    <button
                        v-if="show_btn"
                        @click="setScreenMode"
                        style="width: 40px; height: 40px; background-color: rgba(0, 0, 0, 0.2); border-radius: 100%"
                        class="ml-10"
                    >
                        <img :src="require('@/assets/image/btn_chat_on.svg')" class="width-100" id="btn" v-if="!show_chat">
                        <img :src="require('@/assets/image/btn_chat_off.svg')" class="width-100" id="btn" v-else>
                    </button>
                </div>
            </div>
        </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LiveStreaming'
    , props: ['user', 'cartel_info', 'live_in', 'live_info', 'is_on_stream', 'screen_angle', 'is_on_stage']
    , data: function () {
        return {
            is_streaming: false
            , is_video: true
            , full_screen_mode: false
            , show_layout: false
            , playerOptions: {
                // videojs options
                autoplay: true,
                muted: true,
                language: 'kr',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                poster: this.live_info.cartl_chttg_img_url,
            }
            , show_btn: false
            , is_setting: false
            , show_chat: true
            , is_on_sound: false
            , on_video: false
            , player: []
            , try: 0
        }
    }
    , methods: {
        touchLayout(e){
            console.log(e.target.id)
            console.log('touch')
            const btn = e.target.id
            if(btn !== 'btn'){
                this.show_layout = !this.show_layout
            }
            if(this.show_layout){
                setTimeout(() => {
                    this.show_layout = false
                }, 3000)
            }
        }
        , initializePlayer() {
            if (this.is_on_stream) {
                setTimeout(() => {
                    console.log('player initialize start')
                    window.registerIVSTech(window.videojs)
                    // IVS Player와 Video.js 통합 설정
                    const videoOptions = {
                        techOrder: ["AmazonIVS"],
                        autoplay: true,
                    };
                    this.player = window.videojs('video', videoOptions, () => {
                        console.log('Player is ready to use');
                    });
                    this.player.src(this.live_in.srurl + '?token=' + this.live_in.srt);
                    this.player.play();

                    let playerEvent = this.player.getIVSEvents().PlayerEventType;
                    this.player.getIVSPlayer().addEventListener(playerEvent.ERROR, (err) => {
                        if(err.code === 404){
                            setTimeout( () => {
                                this.player.src(this.live_in.srurl + '?token=' + this.live_in.srt);
                                this.player.play();
                            }, 5000)

                        }
                    });
                    this.player.on('waiting', function () {
                        console.log('Video is buffering...');
                        // 버퍼링 상태에서 필요한 처리를 수행
                    });

                    this.player.on('canplay', function () {
                        console.log('Video can start playing.');
                        this.on_video = true
                        // 버퍼링이 완료되고 재생이 시작 가능한 상태에서 필요한 처리를 수행
                    });

                    this.player.on('playing', function () {
                        console.log('Video is playing.');
                        this.on_video = true
                        // 비디오 재생이 시작될 때 필요한 처리를 수행
                    });

                    this.player.on('stalled', function () {
                        console.log('Video playback stalled.');
                        this.on_video = false
                        // 재생이 중단되거나 지연될 때 필요한 처리를 수행
                    });

                    this.is_streaming = true
                },1000)
            }
        }
        , mutedControl() {
            const video = document.getElementById('video')
            video.muted = !video.muted
            // console.log(video.muted)
            this.is_on_sound = !this.is_on_sound
        }
        , offVideo: function () {
            console.log('off')
            this.is_streaming = false
            this.is_video = false
            let video = document.getElementById('video');
            if(video) {
                video.src = '';
            }
            setTimeout(() => {
                this.is_video = true
            }, 100)
        }
        , onVideoError: function (evt, err) {
            console.log(evt, err)
            console.log('onVideoError')
            if(this.is_streaming){
                setTimeout(() => {
                    this.onVideo()
                }, 100)
            }
        }
        , setScreenMode: function () {
            this.full_screen_mode = !this.full_screen_mode
            this.show_chat = !this.show_chat
            this.$emit('setScreenMode', this.full_screen_mode)
        }
        // , checkIos: function () {
        //     if(this.$common.getMobile() == 'ios'){
        //         try {
        //             window.android.postMessage({
        //
        //             });
        //             let video = this.$refs.videoRef
        //             video.controls = true
        //
        //         }catch (e){
        //             console.log(e)
        //         }
        //     }
        // }
    }
    , created() {
        // this.onVideo()
        this.initializePlayer();
    }
    , watch: {
        is_on_stage: {
            handler: function (call) {
                console.log('is_on_stage', call)
            }
        }
        , is_on_stream: {
            immediate: true
            , deep: true
            , handler: function (call) {
                // console.log('is_on_stream ing', call)
                if (call) {
                    // this.onVideo()
                    this.initializePlayer();
                } else {
                    this.offVideo()
                }
            }
        }
    }
}
</script>

<style>
.image_null {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.25);
}
.image_null img {
    height: 80px;
    width: 80px;
}

.bg-live {
    background-position: 50%;
    background-size: cover;
    background-image: url(../../assets/image/@cartel_cover_basic.png);
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.controller_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
}
.controller {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.35);
    padding: 1rem;
}
.size_controller {
    position: absolute;
    z-index: 100;
    padding: 1.2rem;
    right: 0;
    bottom: 0;
}
.wrap-screen {
    overflow: hidden;
    position: relative;
    height: 200px;
}

.wrap-screen-horizontal.wrap-screen {

}

.wrap-full-screen.wrap-screen {
    height: 100%;
    max-height: 100%;
}

#video div, video {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.box-screen {
    object-fit: cover;
    max-height: 100%;
}

.wrap-full-screen .box-screen {
    object-fit: contain;
    height: 100%;
}

.box-full-screen.box-screen {
    width: 100%;
}

.box-screen-horizontal.box-screen {

}
</style>