<template>
    <div class="position-fixed bg-white" style="z-index: 99">
        <div class="header filter">
            <!-- 해더 -->
            <div class="cartel_top">
                <div class="top top_mafi f-left">
                    <div class="page_tit_area">
                        <h2 class="page_tit">{{  program.title }}</h2>
                        <span class="page_subtit">{{  item_cartel.cartl_name }}</span>
                    </div>
                    <div class="save">
                        <button
                            @click="$emit('closeModal')"
                            class="btn_save"
                        ><v-icon class="color-white">mdi-close</v-icon></button>
                    </div>
                </div>
            </div>
            <!-- //해더 -->
            <!-- 친구검색 -->
            <div class="serch_top">
                <div class="top top_wh">
                    <div class="header_search">
                        <input
                            v-model="keyword"
                            type="text" :placeholder="$language.common.search_member"
                            maxlength="20"
                            v-on:keyup.enter="searchUser"
                        >
                    </div>
                    <div class="header_search_btn">
                        <div class="btn_del">
                            <!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
                            <button
                                v-if="keyword"
                                class="active"
                                @click="keyword = ''"
                            ><span class="hide">{{ $language.common.close }}</span></button>
                        </div>
                        <div class="util">
                            <button @click="searchUser"
                                class="img_button"
                            ><span class="hide">{{ $language.common.search }}</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //친구검색 -->
        </div>
        <!-- //고정해더 -->
        <!-- 컨텐츠 내용 -->
        <div class="section_wrap pt-120">
            <!-- 멤버초대 -->
            <div class="thumbnail_wrap">
                <div class="container">
                    <div class="row">
                        <div class="user_tit mt-20">
                            <h3>{{ $language.common.live_member }}</h3>
                            <h3>{{ participant_list.length }}명</h3>
                        </div>

                        <ul class="thumbnail_list ct_invite pt-0">
                            <h3 class="hide">{{ $language.common.friend }} {{ $language.common.search }} {{ $language.common.result }}</h3>
                            <li
                                v-for="(item, index) in participant_list"
                                :key="'item_' + index"
                            >
                                <div class="ct_invite_list">
                                    <div class="circle_thumbnail_item">
                                        <div class="clear">
                                            <div class="picture">
                                                <img
                                                    v-if="item.profle_nft_card_img_url"
                                                    :src="$request.upload_url(item.profle_nft_card_img_url)"
                                                >
                                                <img
                                                    v-else
                                                    :src="require('@/assets/image/@profile_inner.png')"
                                                />
                                            </div>
                                            <div class="text_area">
                                                <strong>{{ item.nickname }}</strong>
                                                <p class="textzone textzone" v-if="item.cartl_member_grade_name === '카르텔 개설자'">
                                                    {{ $language.cartel.cartel_boss }}
                                                </p>
                                                <p class="textzone textzone" v-else>
                                                    {{ $language.cartel.cartel_member }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="ct_invite_btn" v-if="item.cartl_member_div_code !== 'CA02500001' && live_info.chttg_room_progress_member_number == user.member_number">
                                            <button
                                                class="btn_outline_blue btn_s"
                                                @click="selectUser(item)"
                                            >{{ $language.common.compulsory_exit }}</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li
                                v-if="participant_list.length <= 0"
                            >
                                <div class="list_none" style="overflow: hidden;">
                                    <img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
                                    <span>{{ $language.common.no_search_member }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //멤버초대 -->
        </div>
        <!--      강퇴 ( 개설자 )     -->
        <PopupConfirm
            v-if="is_open_popup && live_info.chttg_room_progress_member_number == this.user.member_number"
            @cancel="closePopup()"
            @click="compulsory"
        >
            <template v-slot:title>{{ $language.live.live_compulsory_exit_title }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_compulsory_exit | nl2br"></div>
            </template>
            <template
                v-slot:sub-txt
            >
                <p class="mt-10">{{ selected_user.nickname }}</p>
            </template>
            <template
                v-slot:name-cancel
            >{{ $language.common.system_notice_btn02 }}
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.compulsory_exit }}
            </template>

        </PopupConfirm>
    </div>
</template>

<script>
import PopupConfirm from "@/view/Layout/PopupConfirm.vue";

export default {
    name: "LiveUserList"
    ,
    components: {PopupConfirm},
    props: ['participant_list', 'live_info', 'user', 'item_cartel']
    , data() {
        return {
            program: {
              title: this.$language.live.live_chat_participants
            }
            , item_search: {search_value: ''}
            , is_open_popup: false
            , selected_user: []
            , keyword: ''
        }
    }
    , methods: {
        compulsory: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_compulsory_exit
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                        , exit_member_number: this.selected_user.member_number
                    }
                    , type: true
                })

                if (result.success) {
                    console.log(result)
                    this.closePopup()
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , selectUser: function (item) {
            this.selected_user = item
            this.is_open_popup = true
            console.log(this.selected_user)
        }
        , closePopup(){
            this.is_open_popup = false
            this.selected_user = []
        }
        ,searchUser() {
            console.log(this.keyword)
            this.$emit('searchUser',this.keyword)
        }
    }

}
</script>

<style scoped>

</style>