<template>
    <div class="position-relative flex justify-center"
         style="width: 100%; align-items: center"
         :style="screen_angle === 0 && !full_screen_mode ? 'height:200px' : 'height:100%'"
         >
        <div class="flex justify-center position-relative bg-live" style="overflow: hidden; align-items: center; height: 100%"
             :style="item_live.cartl_chttg_img_url
                ? 'background-image: url(' + item_live.cartl_chttg_img_url + ');'
                : ''"  @click="touchLayout">

            <canvas
                v-if="is_webcam"
                id="preview"
                style="width: 100% !important; height: 100% !important; object-fit: cover;"
            ></canvas>
        </div>
        <div class="position-absolute full-width full-height" style="top:0; left: 0;"
             v-if="!is_streaming && !webcam" @click="touchLayout">
            <div class="image_null">
                <img src="@/assets/image/ani_icon_preparing.gif" alt="image_null">
                <p class="size-px-14 color-white font-weight-400">{{ $language.live.live_ready }}</p>
            </div>
        </div>
        <div class="size_controller">
            <button style="color: white" v-if="!full_screen_mode && !show_layout" @click="$emit('fullMode')">
                <img src="@/assets/image/btn_chat_off.svg" alt="full_screen">
            </button>
            <button style="color: white" v-if="full_screen_mode && !show_layout" @click="$emit('defaultMode')">
                <img src="@/assets/image/btn_chat_on.svg" alt="default_screen">
            </button>
        </div>
        <transition name="fade">
            <div class="controller" v-if="show_layout">
                <div class="size_controller">
                    <button style="color: white" v-show="!full_screen_mode" @click="$emit('fullMode')">
                        <img src="@/assets/image/full_screen.svg" alt="full_screen">
                    </button>
                    <button style="color: white" v-show="full_screen_mode" @click="$emit('defaultMode')">
                        <img src="@/assets/image/default_screen.svg" alt="default_screen">
                    </button>
                </div>
                <div>
                    <label
                        v-if="is_streaming"
                        class="label label-danger"
                    >LIVE</label>
                    <label
                        v-else
                        class="label label-default"
                    >READY</label>
                </div>
                <button
                    @click="is_setting = true"
                    style="width: 25px; height: 25px; background-color: rgba(0, 0, 0, 0.2); border-radius: 100%"
                ><img :src="require('@/assets/image/img_set.png')" class="width-100"></button>
            </div>
        </transition>

		<!-- Error alert -->

		<PopupLayer
			v-show="is_setting"
			style="width: 100%;"
		>
            <template
                v-slot:body
            >
                <div class="w-100 flex justify_content_center">
                    <div class="bg-white radius-20 overflow-hidden" style="max-width: 450px">
                        <div class="pa-20">
                            <div
                                class=" flex-row justify-space-between under-line pb-10"
                            >
                                <h3 class="">{{ $language.live.live_streaming_setting }}</h3>
                                <button
                                    @click="is_setting = false"
                                >
                                    <v-icon class="color-333">mdi mdi-close-circle</v-icon>
                                </button>
                            </div>
                            <div>
                                <div class="mt-10">
                                    <label for="video-devices">Webcam</label>
                                    <select
                                        v-model="webcam"
                                        disabled id="video-devices"
                                        class="box width-100 pa-10 radius-10 mt-10"
                                    >
                                        <option selected disabled>Choose Option</option>
                                    </select>
                                </div>
                                <div class="mt-10">
                                    <label for="audio-devices"> Microphone</label>
                                    <select
                                        disabled id="audio-devices"
                                        class="box width-100 pa-10 radius-10 mt-10"
                                    >
                                        <option selected disabled>Choose Option</option>
                                    </select>

                                    <select
                                        style="display: none"
                                        disabled id="stream-config"
                                    >
                                        <option selected disabled>Choose Option</option>
                                    </select>
                                </div>

                                <!-- Ingest Endpoint input -->
                                <section
                                    class="container"
                                >
                                    <input
                                        v-model="live_in.surl"
                                        type="hidden" id="ingest-endpoint"
                                    />
                                </section>

                                <!-- Stream Key input -->
                                <section class="container">
                                    <input
                                        v-model="live_in.sk"
                                        type="hidden" id="stream-key"
                                    />
                                </section>

                                <section class="container">
                                    <h3 id="error"></h3>
                                </section>

                                <!-- Broadcast buttons -->
                                <div class="btn_area mt-30 flex-column">
                                    <button
                                        v-show="!is_on_stream"
                                        class="btn_l btn_fill_blue mb-5" id="start" @click="postStartBroadcast()"
                                        :disabled="!is_start"
                                    >{{ $language.live.live_on }}
                                    </button>
                                    <button
                                        v-show="is_on_stream"
                                        class="btn_l btn_fill_red mb-5" id="stop" disabled @click="offStream()"
                                    >{{ $language.live.live_off }}
                                    </button>
                                    <button
                                        v-show="!is_on_stream"
                                        class="btn_l btn_fill_red" @click="stopChat()"
                                    >{{ $language.live.chat_room_off }}
                                    </button>
                                </div>

                                <hr/>

                                <!-- Data table -->
                                <section class="container">
                                    <table id="data">
                                        <tbody></tbody>
                                    </table>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </template>
        </PopupLayer>
	</div>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'LiveSetting'
		,
		components: {PopupLayer},
		props: ['user', 'cartel_info', 'live_info', 'live_in', 'screen_angle', 'is_on_stage', 'full_screen_mode', 'is_on_stream']
		, data: function(){
			return {
				item_live: this.live_info
				, item_live_in: this.live_in
				, channelConfigs: []
				, config: []
				, is_setting: false
				, is_streaming: false
				, client: ''
				, webcam: ''
				, is_webcam: true
                , show_layout: false

			}
		}
		, computed: {
			is_start: function(){
				let t = false

				if(this.webcam){
					t = true
				}
				return t
			}
		}
		, methods: {
            touchLayout(){
                this.show_layout = !this.show_layout
                setTimeout(() => {
                    this.show_layout = false
                }, 1500)
            }
			// Error helpers
			, clearError: function() {
				const errorEl = document.getElementById("error");
				errorEl.innerHTML = "";
			}

			, setError: function(message) {
				if (Array.isArray(message)) {
					message = message.join("<br/>");
				}
				const errorEl = document.getElementById("error");
                console.log(message)
				errorEl.innerHTML = message;
			}

			, getSupportedProperty: function(object, key) {
				if (key in object) {
					return object[key];
				}

				return "Unsupported";
			}

			// Get available audio/video inputs
			, initializeDeviceSelect: async function() {
				const videoSelectEl = document.getElementById("video-devices");

				videoSelectEl.disabled = false;
				videoSelectEl.options[0] = new Option("None", "");
				const { videoDevices, audioDevices } = await this.getDevices();
				videoDevices.forEach((device, index) => {
					videoSelectEl.options[index + 1] = new Option(device.label, device.deviceId);
					if(index == 0){
						// document.getElementById('video-devices').value = device.deviceId
					}
				});

				const audioSelectEl = document.getElementById("audio-devices");

				audioSelectEl.disabled = false;
				audioSelectEl.options[0] = new Option("None", "None");
				audioDevices.forEach((device, index) => {
					audioSelectEl.options[index + 1] = new Option(
						device.label,
						device.deviceId
					);
					if(index == 0){
						document.getElementById('audio-devices').value = device.deviceId
					}
				});
			}

			, getCamera: async function(deviceId, maxWidth, maxHeight) {

				let media;
				const videoConstraints = {
					deviceId: deviceId ? { exact: deviceId } : null,
					width: {
						max: maxWidth
					},
					height: {
						max: maxHeight
					}
				};

                console.log('videoConstraints', videoConstraints)
				try {
					// Let's try with max width and height constraints
					if(!deviceId){
						throw 'not device id'
					}
					media = await navigator.mediaDevices.getUserMedia({
						video: videoConstraints,
						audio: true
					});
				} catch (e) {
					// and fallback to unconstrained result
					delete videoConstraints.width;
					delete videoConstraints.height;
					delete videoConstraints.active;
					delete videoConstraints.id;
					media = await navigator.mediaDevices.getUserMedia({
						video: videoConstraints
					});
				}
				return media;
			}

			// Handle video device retrieval
			, handleVideoDeviceSelect: async function() {

				const id = "camera";
				const videoSelectEl = document.getElementById("video-devices");
				const { videoDevices: devices } = await this.getDevices();
				if (this.client.getVideoInputDevice(id)) {
					this.client.removeVideoInputDevice(id);
				}

				// Get the option's video
				const selectedDevice = devices.find(
					(device) => device.deviceId === videoSelectEl.value
				);
				const deviceId = selectedDevice ? selectedDevice.deviceId : null;

				const { width, height } = this.config.streamConfig.maxResolution;

                const cameraStream = await this.getCamera(deviceId, width, height);

                // Add the camera to the top
                if (deviceId !== null) {
                    const previewEl = document.getElementById("preview");
                    this.client.attachPreview(previewEl);

                    await this.client.addVideoInputDevice(cameraStream, id, {
                        index: 0
                    });
                } else {
                    this.client.detachPreview()
                    console.log(this.is_streaming, 'is streaming')
                    if (this.is_streaming) {
                        await this.stopBroadcast()
                    }
                    this.is_webcam = false
                    setTimeout(() => {
                        this.is_webcam = true
                    }, 100)
                }
            }

            , hasFrontBack: async function() {
                let result = {hasBack: false, hasFront: false, videoDevices: []}
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true}).then( (e) => {
						console.log(11, e)
					} ).catch( (e) => {
						console.log(22, e)
					} )
                    let devices = await navigator.mediaDevices.enumerateDevices()
                    const videoDevices = devices.filter(device => {
                        if (device.kind === 'videoinput') {
                            if (device.label && device.label.length > 0) {
                                if (device.label.toLowerCase().indexOf('back') >= 0) {
                                    result.hasBack = true
                                } else if (device.label.toLowerCase().indexOf('front') >= 0) {
                                    result.hasFront = true
                                } else { /* some other device label ... desktop browser? */ }
                            }
                            return true
                        }
                        return false
                    })
                    console.log(videoDevices, 'videoDevices')
                    result.videoDevices = videoDevices
                    /* drop stream */
                    if(stream){
                        const tracks = stream.getTracks()
                        if (tracks) {
                            for (let t = 0; t < tracks.length; t++) tracks[t].stop()
                        }
                        return result
                    }
                }
                catch (ex) {
                    /* log and swallow exception, this is a probe only */
                    console.error('hasFrontBack', ex)
                    return result
                }
            }

	// Handle audio/video device enumeration
			, getDevices: async function() {
				try{

					await this.hasFrontBack()
					const devices = await navigator.mediaDevices.enumerateDevices();
					const videoDevices = devices.filter((d) => d.kind === "videoinput");
					if (!videoDevices.length) {
						this.setError("No video devices found.");
					}
					const audioDevices = devices.filter((d) => d.kind === "audioinput");
					if (!audioDevices.length) {
						this.setError("No audio devices found.");
					}

					return { videoDevices, audioDevices };
				}catch (e){
					console.log('getDevices', e)
				}
			}

			// Handle audio device retrieval
			, handleAudioDeviceSelect: async function () {
				const id = "microphone";
				const audioSelectEl = document.getElementById("audio-devices");
				const { audioDevices: devices } = await this.getDevices();
				if (this.client.getAudioInputDevice(id)) {
					this.client.removeAudioInputDevice(id);
				}
				if (audioSelectEl.value.toLowerCase() === "none") return;
				const selectedDevice = devices.find(
					(device) => device.deviceId === audioSelectEl.value
				);
				// Unlike video, for audio we default to "None" instead of the first device
				if (selectedDevice) {
					const microphoneStream = await navigator.mediaDevices.getUserMedia({
						audio: {
							deviceId: selectedDevice.deviceId
						}
					});
					await this.client.addAudioInputDevice(microphoneStream, id);
				}
			}

			// Setup the stream configuration options
			, initializeStreamConfigSelect: async function () {
				const streamConfigSelectEl = document.getElementById("stream-config");
				streamConfigSelectEl.disabled = false;

				this.channelConfigs.forEach(([configName], index) => {
					streamConfigSelectEl.options[index] = new Option(configName, index);
				});
			}

			// Handle setting the stream config
			, handleStreamConfigSelect: async function () {
				const streamConfigSelectEl = document.getElementById("stream-config");
				const selectedConfig = streamConfigSelectEl.value;
				this.config.streamConfig = this.channelConfigs[selectedConfig][1];

				await this.createClient();
			}

			/**
			 * Validates the form's input elements. Returns empty array if
			 * valid else the list of errors.
			 */
			, validate: function() {
				const streamKey = document.getElementById("stream-key").value;
				const ingestUrl = document.getElementById("ingest-endpoint").value;
				const errors = [];

				if (!ingestUrl) {
					errors.push("Please provide an ingest endpoint");
				}

				if (!streamKey) {
					errors.push("Please provide a stream key");
				}

				return errors;
			}

			, handleIngestEndpointChange: async function(e) {
				this.handleValidationErrors(this.validate());

				try {
					this.client.config.ingestEndpoint = e.target.value;
				} catch {
					this.handleValidationErrors(["Incorrect Ingest Url"]);
				}
			}

			, handleStreamKeyChange: function () {
				this.handleValidationErrors(this.validate());
			}

			, handleValidationErrors: function (errors, doNotDisplay) {
				const start = document.getElementById("start");
				const stop = document.getElementById("stop");

				this.clearError();
				if (errors && errors.length) {
					// Display errors
					if (!doNotDisplay) {
						this.setError(errors);
					}

					// Disable start and stop buttons
					start.disabled = true;
					stop.disabled = true;
					return;
				}

				// start.disabled = false;
			}

			// Start the broadcast
			, startBroadcast: async function () {
				// const streamKeyEl = document.getElementById("stream-key");
				// const endpointEl = document.getElementById("ingest-endpoint");

				const start = document.getElementById("start");

				try {
					start.disabled = true;
					this.is_streaming = true
                    console.log('startBroadcast', this.live_in.sk, this.live_in.surl)
                    console.log(this.client)
					await this.client.startBroadcast(this.live_in.sk, this.live_in.surl);
					setTimeout( () => {
						this.$emit('onStream', { sk: this.live_in.sk, surl: this.live_in.surl})
					}, 1000)

				} catch (err) {
					start.disabled = false;
					this.is_streaming = false
					this.setError(err.toString());
					// this.$emit('offStream')
				}
			}
            , offStream: async function () {
                try {
                    this.$bus.$emit('on', true)
                    const result = await this.$Request({
                        method: 'post'
                        , url: this.$api_url.api_path.post_cartel_live_finish
                        , data: {
                            member_number: this.user.member_number
                            , cartl_number: this.$route.params.idx
                            , cartl_chttg_room_number: this.$route.params.live_idx
                        }
                        , type: true
                    })

                    if (result.success) {
                        console.log(result.data)
                        console.log('stream offfff')
                    } else {
                        throw result.message
                    }
                } catch (e) {
                    console.log(e)
                    this.$bus.$emit('notify', {type: 'error', message: e})
                } finally {
                    this.$bus.$emit('on', false)
                }
            }

			// Stop the broadcast
			, stopBroadcast: async function () {
                console.log('offffff')
				try {
					await this.client.stopBroadcast();
                    await this.offStream()
				} catch (err) {
					this.setError(err.toString());
				}
			}

            // Stop the chat
			, stopChat: async function () {
				try {
					// await this.client.stopChat();
					this.$emit('offChat')
				} catch (err) {
					this.setError(err.toString());
				}
			}

			// Handle the enabling/disabling of buttons
			, onActiveStateChange: function (active) {

				const start = document.getElementById("start");
				const stop = document.getElementById("stop");
				const streamConfigSelectEl = document.getElementById("stream-config");
				const inputEl = document.getElementById("stream-key");
				inputEl.disabled = active;
				start.disabled = active;
				stop.disabled = !active;
				streamConfigSelectEl.disabled = active;
				this.is_streaming = active
			}

			// Helper to create an instance of the AmazonIVSBroadcastClient
			, createClient: async function () {
				if (this.client) {
					this.client.delete();
				}

				this.client = window.IVSBroadcastClient.create(this.config);
                console.log('client config', this.config)

				this.client.on(
					window.IVSBroadcastClient.BroadcastClientEvents.ACTIVE_STATE_CHANGE,
					(active) => {
						this.onActiveStateChange(active);
					}
				);

				// const previewEl = document.getElementById("preview");
				// this.client.attachPreview(previewEl);

				// await this.handleVideoDeviceSelect();
				await this.handleAudioDeviceSelect();
			}

			// Initialization function
			, init: async function () {
				try {
					const videoSelectEl = document.getElementById("video-devices");
					const audioSelectEl = document.getElementById("audio-devices");
					const streamConfigSelectEl = document.getElementById("stream-config");
					const ingestEndpointInputEl = document.getElementById("ingest-endpoint");
					const streamKeyInputEl = document.getElementById("stream-key");

					await this.initializeStreamConfigSelect();

					videoSelectEl.addEventListener("change", this.handleVideoDeviceSelect, true);
					audioSelectEl.addEventListener("change", this.handleAudioDeviceSelect, true);
					streamConfigSelectEl.addEventListener(
						"change",
						this.handleStreamConfigSelect,
						true
					);
					ingestEndpointInputEl.addEventListener(
						"input",
						this.handleIngestEndpointChange,
						true
					);
					streamKeyInputEl.addEventListener("input", this.handleStreamKeyChange, true);

					// Get initial values from the text fields.  Changes to these will re-create the client.
					const selectedConfig = streamConfigSelectEl.value;
					this.config.streamConfig = this.channelConfigs[selectedConfig][1];
					this.config.ingestEndpoint = ingestEndpointInputEl.value;

					await this.createClient();

					await this.initializeDeviceSelect();

					this.handleValidationErrors(this.validate(), true);
				} catch (err) {
					this.setError(err.message);
				}
			}
			, postStartBroadcast: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_live_start
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_chttg_room_number: this.$route.params.live_idx
						}
						, type: true
					})
                    if(result.success){
                        console.log('start broadcast success result')
                        console.log(result)

                    }else{
                        throw result.message
                    }
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {

			this.channelConfigs = [
				["Basic: Landscape", window.IVSBroadcastClient.BASIC_LANDSCAPE],
				["Basic: Portrait", window.IVSBroadcastClient.BASIC_PORTRAIT],
				["Standard: Landscape", window.IVSBroadcastClient.STANDARD_LANDSCAPE],
				["Standard: Portrait", window.IVSBroadcastClient.STANDARD_PORTRAIT]
			]

			// Set initial config for our broadcast
			this.config = {
				// ingestEndpoint: "https://g.webrtc.live-video.net:4443",
				ingestEndpoint: 'UNIQUE_ID.global-contribute.live-video.net',
				// streamConfig: window.IVSBroadcastClient.BASIC_LANDSCAPE,
				streamConfig: window.IVSBroadcastClient.BASIC_LANDSCAPE,
				logLevel: window.IVSBroadcastClient.LOG_LEVEL.DEBUG
			}

			try{

				this.$common.inAppWebviewCommunity('android', 'permission')

				window.click = () => {
					this.init()
				}
				window.deninePermission = () => {
					this.$bus.$emit('notify', { type: 'error', message: '라이브 채팅에 필요한 권한이 거부되어 있습니다. 앱 설정에서 권한승인이 필요합니다.'})
				}
			}catch (e){

				setTimeout( () => {
					this.init()
				}, 100)
			}

        }
        , watch: {
            is_on_stream: {
                immediate: true
                , handler: function (call) {
                    console.log('setting is_on_steam', call)
                    if (call) {
                        console.log('start stream')
                        this.startBroadcast()
                    } else {
                        console.log('stop stream')
                        if(this.is_streaming){
                            this.stopBroadcast()
                        }
                    }
                }
            },
            live_info: {
                immediate: true
                , handler: function (){
                    this.item_live = this.live_info
                }
            }
        },
    }
</script>

<style>
.image_null {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.25);
}
.image_null img {
    height: 80px;
    width: 80px;
}

.bg-live {
    background-position: 50%;
    background-size: cover;
    background-image: url(../../assets/image/@cartel_cover_basic.png);
}

.fade-enter-active,

.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.controller {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
}
.size_controller {
    position: absolute;
    z-index: 5;
    padding: 1.2rem;
    right: 0;
    bottom: 0;
}
.label {
    display: inline-block !important;
    padding: 3px 10px;
    border-radius: 20px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
}

.label-default {
    background-color: #bbb !important;
    color: white !important;
}

.label-progress {
    background-color: var(--blue01);
    color: white
}

.label-success {
    background-color: #1EA76E;
    color: white;
}

.label-warning {
    background-color: #f44336;
    color: white;
}

.label-danger {
    background-color: #FC4545;
    color: white !important;
}

.label-cancel {
    background-color: #f44336;
    color: white !important;
}

.label-notice {
    background-color: #DC505C;
    color: white
}

.label-primary {
    background-color: var(--blue01) !important;
    color: white !important;
}
</style>