<template>
    <div class="full-height size-px-12"
         :class="{
            'flex-row': screen_angle === 90 && !full_screen_mode,
            'flex-column': screen_angle === 0 && !full_screen_mode,
        }"
    >
        <div
            v-if="screen_angle != 90 && !full_screen_mode"
            class="bg-blue " style="padding: 0 2rem; height: 5rem;"
        >
            <div
                class="flex-row justify-space-between items-center full-height"
            >
                <button
                    @click="onBack"
                    class="mr-10 arrow_white"
                >
                    <i class="icon icon-arrow-left color-white font-weight-bold size-px-15"></i>
                </button>
                <div class="flex-1 overflow-hidden">
                    <Marquee class="color-white size-px-16 font-weight-bold">
                        <template
                            v-slot:text
                        >{{ item_live.cartl_chttg_room_name }}
                        </template>
                    </Marquee>
                </div>
                <button class="pl-10" @click="openModifyPopup" v-if="item_live.chttg_room_progress_member_number == this.user.member_number">
                    <img src="@/assets/image/write_icon.svg" alt="modify">
                </button>
            </div>
        </div>

        <div
            class="flex-row overflow-hidden bg-black"
            :class="{
             'full-screen': screen_angle === 90 && full_screen_mode,
             'vertical-full-screen': screen_angle === 0 && full_screen_mode,
             'vertical-screen': screen_angle === 0 && !full_screen_mode,
             'horizontal-screen': screen_angle === 90 && !full_screen_mode,
             }"
        >
            <LiveSetting
                v-if="item_live.chttg_room_progress_member_number == this.user.member_number"
                :user="user"
                :live_info="item_live"
                :live_in="item_live_in"
                :screen_angle="screen_angle"
                :class="screen_angle_class_cam"
                :full_screen_mode="full_screen_mode"
                :is_on_stage="is_on_stage"
                :is_on_stream="is_on_stream"

                @fullMode="full_screen_mode = true"
                @defaultMode="full_screen_mode = false"
                @chatOff="is_chat_off = true"
                @onStream="onStream"
                @offStream="offStream"
                @offChat="is_open_chat_off = true"
                class="z-index-layer"
            ></LiveSetting>
            <LiveStreaming
                v-else-if="item_live.chttg_room_progress_member_number != this.user.member_number"
                :user="user"
                :live_info="item_live"
                :live_in="item_live_in"
                :is_on_stage="is_on_stage"
                :is_on_stream="is_on_stream"
                :screen_angle="screen_angle"
                :class="screen_angle_class_cam"
                :full_screen_mode="full_screen_mode"

                @fullMode="full_screen_mode = true"
                @defaultMode="full_screen_mode = false"

                class="z-index-layer-chat"

                @setScreenMode="setScreenMode"
            ></LiveStreaming>

            <!--      채팅방 나가기      -->
            <PopupConfirm
                v-if="is_on_out"
                @cancel="offBack"
                @click="goBack"
            >
                <template v-slot:title>{{ $language.live.live_out }}</template>
                <template
                    v-slot:main-txt
                >
                    <div :inner-html.prop="$language.live.live_out_txt | nl2br"></div>
                </template>
                <template
                    v-slot:name-cancel
                >{{ $language.common.system_notice_btn02 }}
                </template>
                <template
                    v-slot:name-confirm
                >{{ $language.live.live_out_btn }}
                </template>

            </PopupConfirm>

            <!--      채팅방 종료 ( 개설자 )     -->
            <PopupConfirm
                v-if="is_open_chat_off && item_live.chttg_room_progress_member_number == this.user.member_number"
                @cancel="is_open_chat_off = false"
                @click="offChat"
            >
                <template v-slot:title>{{ $language.live.chat_room_off }}</template>
                <template
                    v-slot:main-txt
                >
                    <div :inner-html.prop="$language.live.live_chat_off_txt | nl2br"></div>
                </template>
                <template
                    v-slot:name-cancel
                >{{ $language.common.system_notice_btn02 }}
                </template>
                <template
                    v-slot:name-confirm
                >{{ $language.common.destroy }}
                </template>

            </PopupConfirm>

            <!--      채팅방 종료 ( 참여자 )     -->
            <PopupConfirm
                v-if="is_chat_off && item_live.chttg_room_progress_member_number != this.user.member_number"
                :not_cancel="true"
                @click="endChat"
            >
                <template v-slot:title>{{ $language.live.live_off }}</template>
                <template
                    v-slot:main-txt
                >
                    <div :inner-html.prop="$language.live.live_off_txt | nl2br"></div>
                </template>
                <template
                    v-slot:name-confirm
                >{{ $language.common.ok }}
                </template>

            </PopupConfirm>
            <LiveRequestModify
                v-if="is_open_modify"
                :user="user"
                :live_info="item_live"
                :is_open_modify="is_open_modify"

                @closeModify="closeModify"
            ></LiveRequestModify>
        </div>
        <div v-show="!full_screen_mode"
             :class="{
             'w-100': screen_angle === 0 && !full_screen_mode,
             'vertical-chat': screen_angle === 0 && !full_screen_mode,
             'horizontal-chat': screen_angle === 90 && !full_screen_mode,
             }"
        >
            <LiveChat
                v-if="is_live"
                :user="user"
                :live_info="item_live"
                :live_in="item_live_in"
                :is_out="is_out"
                :screen_angle="screen_angle"
                :items_list="items_list"

                @chatOff="is_chat_off = true"
                @userOut="userOut"
                @onUser="getData"
                @onStage="onStage"
                @onStream="onStream"
                @onSetting="onSetting"
                @offStream="offStream"
                @sendMessage="sendMessage"
                @showUserList="show_participant = true"

                :class="screen_angle_class"
                :full_screen_mode="full_screen_mode"
            ></LiveChat>
        </div>
        <!--      강퇴 ( 참여자 )     -->
        <PopupConfirm
            v-if="is_compulsory && item_live.chttg_room_progress_member_number != this.user.member_number"
            :not_cancel="true"
            @click="$emit('goBack')"
        >
            <template v-slot:title>{{ $language.live.live_compulsory_exit_title_user }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_compulsory_exit_user | nl2br"></div>
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.ok }}
            </template>

        </PopupConfirm>
<!--        네트워크 오류-->
        <PopupConfirm
            v-if="is_network_err"
            :not_cancel="true"
            @click="$emit('goBack')"
        >
            <template v-slot:title>{{ $language.live.live_network_error_title }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_network_error | nl2br"></div>
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.ok }}
            </template>

        </PopupConfirm>
        <LiveUserList
            v-if="show_participant"

            :user="user"
            :participant_list="participant_list"
            :live_info="item_live"
            :item_cartel="item_cartel"

            @searchUser="getParticipant"
            @closeModal="show_participant = false"
        />
    </div>
</template>

<script>
import LiveChat from "@/view/LiveChat/LiveChat";
import Marquee from "@/components/Marquee";
import LiveSetting from "@/view/LiveChat/LiveSetting";
import LiveStreaming from "@/view/LiveChat/LiveStreaming";
import LiveRequestModify from "@/view/LiveChat/LiveRequestModify";
import PopupConfirm from "@/view/Layout/PopupConfirm";
import LiveUserList from "@/view/LiveChat/LiveUserList.vue";

export default {
    name: 'LiveInfo'
    , components: {
        LiveUserList,
        PopupConfirm
        , LiveStreaming
        , LiveSetting
        , Marquee
        , LiveChat
        , LiveRequestModify
    }
    , props: ['user']
    , data: function () {
        return {
            program: {
                name: 'live detail'
                , title: '라이브'
                , not_header: true
                , not_footer: true
                , type: 'cartel'
            }
            , item_cartel: {}
            , item_live: {}
            , item_live_in: {
                srt: ''
            }
            , items_comment: []
            , full_screen_mode: false
            , is_on_stage: false
            , is_on_stream: false
            , is_on_out: false
            , is_out: false
            , is_open_modify: false
            , is_chat_off: false
            , is_open_chat_off: false
            , screen_angle : 0
            // socket
            , socket: ''
            , items: []
            , item_chat: ''
            , cmm: ''
            , sk: '' // 스트리밍 KEY
            , surl: '' // 스트리밍 엔드포인트
            , srurl: '' // 스트리밍 수신 URL
            , srt: '' // 스트리밍 수신 토큰
            , spt: '' // 스트리밍 참여 토큰
            , try: 0
            , ping: 0
            , ping_timeout: ''
            , is_shift: false
            , is_open_chat: true
            , participant_list: []
            , show_participant: false
            , is_compulsory: false
            , is_network_err: false
        }
    }
    , computed: {
        is_live: function () {
            let t = false
            if (this.item_live.cartl_chttg_room_state_code == 'CA02600002' && this.item_live_in.chttg_participation_token) {
                t = true
            }
            return t
        }
        , screen_angle_class: function () {
            let t = ''
            if (this.screen_angle == '90') {
                t = ' screen-horizontal '
            } else {
                t = ''
            }
            if (this.full_screen_mode) {
                t += ' full-screen-mode '
            }
            return t
        }
        , screen_angle_class_cam: function () {
            let t = ''
            if (this.screen_angle == '90') {
                t = 'screen-horizontal-cam'
            } else {
                t = ''
            }
            if (this.full_screen_mode) {
                t += ' full-screen-mode '
            }
            return t
        }
        , address: function () {
            let t = ''
            if (this.item_live.cartl_chttg_room_state_code == 'CA02600002' && this.item_live_in.cartl_chttg_room_div_code) {
                t = this.item_live_in.chttg_conn_url
            }

            return t
        }
        , ctoken: function () {

            let t = ''

            t = this.item_live_in.chttg_participation_token


            return t
        }
        , items_list: function () {
            let t = this.items
            return t
        }
    }
    , mounted() {
        window.addEventListener(
            "orientationchange",
            () => {
                console.log(window.matchMedia("(orientation: portrait)").matches)
                if (window.matchMedia("(orientation: portrait)").matches) {
                    // you're in PORTRAIT mode
                    this.screen_angle = 90
                }

                if (window.matchMedia("(orientation: landscape)").matches) {
                    // you're in LANDSCAPE mode
                    this.screen_angle = 0
                }
            }
        );
    }
    , methods: {
        getCartel: async function(){
            try{
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_info
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
                    }
                    , type: true
                })

                if(result.success){
                    this.item_cartel = result.data

                    switch (this.item_cartel.cartl_concern_sphere_code){
                        case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
                        case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
                        case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
                        case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
                    }
                }else{
                    throw result.message
                }
            }catch (e) {
                this.$log.console(e)
                this.$bus.$emit('notify', { type: 'error', message: e})
            }finally {
                this.$bus.$emit('on', false)
            }
        }
        , getData: async function () {
            try {
                //this.$bus.$emit('on', true)
                console.log('start get data')
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_live_info
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    console.log('live broadcast get detail ---------------------------', result)
                    this.item_live = result.data
                    console.log(this.item_live.participation_member_count)
                    console.log(this.item_live)

                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }

        , postLiveIn: async function () {
            try {
                //this.$bus.$emit('on', true)

                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_in
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    this.item_live_in = result.data
                    console.log('item_live_in get',this.item_live_in)
                    console.log('in getDeviceInfo')
                    // 앱 메세지
                    try {
                        this.$common.inAppWebviewCommunity('android', 'inAppLiveStream')
                        console.log('inAppLiveStream')
                    }catch (e){
                        console.log('디바이스 정보', e)
                    }
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
                await this.goBack()
            } finally {

                this.$bus.$emit('on', false)
            }
        }
        , onStream: function ({sk, surl, srurl, srt, spt}) {
            console.log('onStream data set', { sk, surl, srurl, srt, spt})
            console.log('onStream data set2',this.item_live.chttg_room_progress_member_number != this.user.member_number)
            if (this.item_live.chttg_room_progress_member_number == this.user.member_number) {

                if (sk) {
                    this.$set(this.item_live_in, 'sk', sk)
                }
                if (surl) {
                    this.$set(this.item_live_in, 'surl', surl)
                }
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                if (spt) {
                    this.$set(this.item_live_in, 'spt', spt)
                }
                this.is_on_stream = true
            } else if (this.item_live.chttg_room_progress_member_number != this.user.member_number) {
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                this.is_on_stream = true
            }

        }
        , onStage: function ({sk, surl, srurl, srt, spt}) {
            console.log('onStage data set', {sk, surl, srurl, srt, spt})
            if (this.item_live.chttg_room_progress_member_number == this.user.member_number) {

                if (sk) {
                    this.$set(this.item_live_in, 'sk', sk)
                }
                if (surl) {
                    this.$set(this.item_live_in, 'surl', surl)
                }
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                if (spt) {
                    this.$set(this.item_live_in, 'spt', spt)
                }
                this.is_on_stream = !!(srurl && srt);
                this.is_on_stage = true
            } else if (this.item_live.chttg_room_progress_member_number != this.user.member_number) {

                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                this.is_on_stream = !!(srurl && srt);
                this.is_on_stage = true
            }

            this.getData()
        }
        , onSetting: function () {
            console.log('설정 변경')
            this.getData()
        }
        , userOut: function (sno) {
            if(this.item_live.chttg_room_progress_member_number === sno) {
                this.offStream()
            }
            this.getData()
        }
        , setScreenMode: function (mode) {
            this.full_screen_mode = mode
        }
        , onBack: function () {
            this.is_on_out = true
        }
        , offBack: function () {
            this.is_on_out = false
        }
        , offStream: function () {
            this.is_on_stream = false
            console.log(this.is_on_stream, 'stream off')
        }
        , offChat: async function () {
            try {
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_expire
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    console.log(result)
                    this.is_out = true
                    this.is_on_out = false
                    this.$router.push({ name:'mafia049', params: { idx: this.$route.params.idx } })
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , endChat: function () {
            this.is_out = true
            this.is_on_out = false
            this.$emit('goBack')
        }
        , goBack: async function () {
            try {
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_liver_out
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    this.endChat()
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , openModifyPopup() {
            this.is_open_modify = true

        }
        , closeModify() {
            this.is_open_modify = false
            this.getData()
        }
        // socket
        ,async connect() {

            this.disconnect()
            console.log('connect', this.socket)
            console.log('is out', this.is_out)

            if(!this.address || !this.ctoken){
                return
            }

            try {
                if (!this.socket || this.socket.readyState === 3) {
                    this.socket = new WebSocket(this.address + '/' + this.ctoken)
                    this.socket.onopen = () => {
                        this.pushItem({
                            cmm: 's'
                            , mt: 'CT00100004'
                            , sno: this.user.member_number
                            , snick: this.user.nickname
                            , ctt: '채팅에 참여하였습니다.'
                        })
                        // this.sendSystemIn('@::/채팅에 참여하였습니다.')
                        this.disabled = false
                    }
                    this.socket.onerror = (error) => {
                        throw error
                    }
                    this.socket.onmessage = ({data}) => {
                        this.onMessage(data)
                        this.ping = 0
                    }
                    this.socket.onclose = (msg) => {
                        console.log('socket close', msg)
                        switch (msg.code) {
                            case 1006:
                                this.pushItem({
                                    cmm: 's'
                                    , mt: 'CT00100001'
                                    , sno: ''
                                    , snick: ''
                                    , ctt: '연결이 종료되었습니다.'
                                })
                                console.log(this.is_chat_off, 'is_chat_off')
                                // if(!this.is_chat_off){
                                //     this.is_network_err = true
                                // }
                                // if (this.try <= 5) {
                                //     setTimeout(() => {
                                //         this.connect()
                                //         this.try++
                                //     }, 1000)
                                // }
                                break;
                            default:

                                this.$bus.$emit('notify', {type: 'error', message: msg.reason})
                                break;
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , onMessage: function (data) {
            data = JSON.parse(data)
            console.log('onMessage', data)
            console.log('onMessage', data.mt)
            switch (data.mt) {
                // 채팅방 입장
                case 'CT00100004':
                    console.log('onStage11111111111111111CT00100004', data)
                    this.srurl = data.srurl // 스트리밍 수신 URL
                    this.srt = data.srt // 스트리밍 수신 토큰
                    this.spt = data.spt // 스트리밍 참여 토큰

                    this.onStage({
                        sk: this.sk,
                        surl: this.surl,
                        srurl: this.srurl,
                        srt: this.srt,
                        spt: this.spt
                    })
                    this.getParticipant()
                    break;

                // 퇴장 메시지
                case 'CT00100010':
                    this.pushItem({
                        cmm: 's'
                        , mt: 'CT00100010'
                        , sno: data.sno
                        , snick: data.snick
                        , ctt: data.snick + '님이 퇴장하셨습니다.'
                    })
                    this.userOut(data.sno)
                    this.getParticipant()
                    break;

                // 전체 메시지
                case 'CT00100002':
                    if (this.user.member_number != data.sno) {

                        this.pushItem({
                            cmm: data.cmm
                            , mt: data.mt
                            , sno: data.sno
                            , snick: data.snick
                            , ctt: data.ctt
                        })

                    }
                    break;
                // 개인 메시지
                case 'CT00100003':
                    break;
                // 설정 변경
                case 'CT00100005':
                    this.onSetting(data.ctt)
                    this.pushItem({
                        cmm: 's'
                        , mt: 'CT00100005'
                        , sno: ''
                        , snick: ''
                        , ctt: '채팅방 설정이  변경되었습니다.'
                    })
                    break;
                // 사용자 권한 변경
                case 'CT00100006':
                    break;
                // 스트리밍 권한 변경
                case 'CT00100007':
                    break;
                // 채팅방 종료
                case 'CT00100008':
                    if(!this.is_chat_off) {
                        this.is_chat_off = true
                        this.socket.close()
                    }

                    break;
                case 'CT00100009':
                    this.getParticipant()
                    this.getData()

                    this.pushItem({
                        cmm: 's'
                        , mt: data.mt
                        , sno: data.sno
                        , snick: data.snick
                        , ctt: data.ctt
                    })
                    break;
                // 스트리밍 시작
                case 'CT00100011':
                    console.log('onStage11111111111111111111111111111111', data)
                    this.cmm = data.cmm // s = 개설자, m = 참여자
                    this.sk = data.sk // 스트리밍 KEY
                    this.surl = data.surl // 스트리밍 엔드포인트
                    this.srurl = data.srurl // 스트리밍 수신 URL
                    this.srt = data.srt // 스트리밍 수신 토큰
                    this.spt = data.spt // 스트리밍 참여 토큰

                    this.onStream({
                        sk: this.sk,
                        surl: this.surl,
                        srurl: this.srurl,
                        srt: this.srt,
                        spt: this.spt
                    })
                    break;
                // 스트리밍 종료
                case 'CT00100012':
                    console.log('stream off', data)
                    this.offStream()

                    break;

                // 참여자 강퇴
                case 'CT00100013':
                    console.log('강퇴당하셨습니다.')
                    this.is_compulsory = true
                    this.is_out = true

                    break;
            }
        }
        , sendSystemIn(msg) {
            let send_message = {
                cmm: 'm'
                , mt: 'CT00100002'
                , sno: this.user.member_number
                , snick: this.user.nickname
                , ctt: msg
            }
            console.log('sendSystemIn', send_message)
            this.socket.send(JSON.stringify(send_message))
            this.ping = 0
        }
        , sendMessage(msg) {
            this.item_chat = msg
            if (this.item_chat) {
                let send_message = {
                    cmm: 'm'
                    , mt: 'CT00100002'
                    , sno: this.user.member_number
                    , snick: this.user.nickname
                    , ctt: this.item_chat
                }
                console.log('sendMessage', send_message)
                this.socket.send(JSON.stringify(send_message))
                this.send(send_message)
                this.ping = 0
            }
        }
        , sendSystemMessage(data) {

            let send_message = {
                cmm: 's'
                , mt: 'CT00100005'
                , sno: this.user.member_number
                , snick: this.user.nickname
                , ctt: data
            }
            console.log('sendSystemMessage', send_message)
            this.socket.send(JSON.stringify(send_message))
        }

        , disconnect() {
            if (this.socket.readyState === 1) {
                this.socket.close()
                this.disabled = true

                this.sendSystemIn("채팅방을 나갔습니다")
            }
        }
        , send: function (data) {
            this.item_chat = ''
            this.pushItem(data)
        }

        , pushItem: function (item) {
            this.items.push(item)


        }
        , onPing: function () {
            console.log('ping', this.ping)
            clearTimeout(this.ping_timeout)
            this.ping_timeout = setTimeout(() => {
                this.ping++
                if (this.ping >= 58) {
                    this.sendPong()
                } else {
                    this.onPing()
                }
            }, 1000)
        }
        , sendPong: function () {
            let send_message = {
                cmm: 's'
                , mt: 'CT00100010'
                , sno: ''
                , snick: ''
                , ctt: 'pong'
            }
            console.log('sendPong', send_message)
            this.socket.send(JSON.stringify(send_message))
            this.ping = 0
        }
        , getParticipant: async function (keyword) {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_live_participation_list
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                        , srchtext: keyword
                    }
                    , type: true
                })

                if (result.success) {
                    this.participant_list = result.data.participation_member_list

                    console.log(result)
                    console.log(this.participant_list,'this.participant_list')

                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        },
    }
    , async created() {
        await this.$bus.$emit('onLoad', this.program)
        await this.getData()
        await this.getCartel()
        await this.postLiveIn()
        await this.connect()

        window.inAppForGround = () => {
            console.log('inAppForGround')
            try {
                this.$router.go(0)
            } catch (e) {
                console.log(e)
            }
        }
        // await this.getParticipant()
    }
    , beforeDestroy() {
        try {
            this.$common.inAppWebviewCommunity('android', 'outAppLiveStream')
            console.log('outAppLiveStream')
        }catch (e){
            console.log('디바이스 정보', e)
        }
    }
    , watch: {
        is_out: {
            handler: function (call) {
                console.log(call, 'is_out')
                if (call) {
                    this.socket.close()
                }
            }
        }
        , item_live_in: {
            immediate: true
            , deep: true
            , handler: (call) => {
                console.log('live detail item_live_in', call)
            }
        }
    }
}
</script>

<style>
.horizontal-screen {
    width: 65%;
    height: 100%;
    flex-direction: row;
}

.vertical-screen {
    min-height: 200px;
    height: 200px;
    flex-direction: column;
}

.horizontal-chat {
    width: 40%;
}

.vertical-chat {
    height: calc(100% - 250px);
}

.full-screen {
    height: 100%;
}

.vertical-full-screen {
    height: 100%;
}

.top-line {
    border-top: 1px solid #ddd
}

.top-shadow {
    box-shadow: 0px 3px 6px black
}

.items_end {
    align-items: end
}

.bg-blue {
    background-color: var(--mafia-Blue)
}

.z-index-layer-chat {
    z-index: 1
}
</style>