<template>
    <div
        v-if="!full_screen_mode"
        class="wrap-chat"
        :class="{ 'wrap-chat-horizontal': screen_angle == 90, 'wrap-chat-full-screen-mode' : full_screen_mode}"
    >

        <div
            class="under-line pa-10" v-if="screen_angle === 0 && !full_screen_mode"
        >
            <div class="size-px-14 flex-row justify-space-between align_items_center">

                <div>
                    <img :src="live_info.profle_nft_card_img_url"
                         @error="$bus.$emit('onErrorImage', $event)"
                         style="width: 32px; height: 32px; border-radius: 100%; object-fit: cover; margin-right: 5px; font-size: 16px"/>
                    <span class="mt-auto ml-3">{{ item_live.chttg_room_progress_member_nickname }}</span>
                </div>
                <div class="flex mr-5 align_items_center" @click="$emit('showUserList')" style="border: 1px solid #DADADA; border-radius: 20px; padding: 3px 10px">
                    <img src="@/assets/image/icon_user_black.svg" alt="user">
                    <span>
                        {{ live_info.participation_member_count }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="!full_screen_mode"
            class="pa-10 overflow-hidden flex-column"
        >
            <div class="pa-10 mb-10 on-notice radius-10 flex align_items_center" v-if="live_info.cartl_chttg_room_desctn !== ''">
                <img src="@/assets/image/notice_icon.svg" alt="notice" class="mr-5">
                {{ live_info.cartl_chttg_room_desctn }}
            </div>
            <div
                ref="box-container"
                class="pa-10 overflow-y-auto"
                style="height: 100%;"
            >
                <ul
                    ref="box-chat"
                    class="mb-10"
                    style="max-height: calc(100% - 50px);"
                >
                    <li
                        v-for="(item, index) in items_list"
                        :key="'item_' + index"
                        class="mb-10 word-break-all"
                        :class="item.cmm == 's' ? 'bg-system' : 'flex-row ' + (item.sno == user.member_number ? 'justify-end' : 'justify-start')"
                    >
                        <div
                            v-if="item.cmm == 's'"
                            class="text-center pa-10"
                        > {{ item.ctt }}
                        </div>
                        <div
                            v-else
                            class="bg-chat radius-10 pa-10" style="width: auto; display: inline-block"
                            :class="{ on: item.sno == user.member_number }"
                        >
                            <div class="font-weight-400 color-gray">{{ item.snick }}</div>
                            <div class="mt-5" :inner-html.prop="item.ctt | nl2br"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="mt-auto top-line top-shadow flex-row justify-space-between align_items_center pa-5"
            v-if="!full_screen_mode"
        >
			<input
                v-model="item_chat"
                ref="item_chat"
                class=" flex-1 size-px-14 mr-10 pl-10"
                @input="reSize"
                @keyup="checkKeyup"
                @keydown="checkKeydown"

                :placeholder="$language.live.send_msg "
                maxlength="250"
                style="ime-mode:active;"
            >
            <button
                @click="sendMessage"
                class="" style="margin-bottom: 8px;"
            >
                <v-icon style="transform: rotate(-35deg)">mdi-send</v-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LiveChat'
    , props: ['user', 'live_in', 'live_info', 'screen_angle', 'full_screen_mode', 'is_out', 'items_list']
    , data: function () {
        return {
            item_live: this.live_info
            , item_live_in: this.live_in
            , item_chat: ''
            , chat_scroll: ''
            , participant_list: []
        }
    }
    , methods: {
        sendMessage: function () {
            this.$emit('sendMessage', this.item_chat)
            this.item_chat = ''
        }
        , reSize: function (e) {
            e.target.style.height = 'auto'

            e.target.style.height = e.target.scrollHeight + 'px'

            if (Number(e.target.style.height.replace('px', '')) >= 120) {
                e.target.style.height = 120 + 'px'
            } else {
                if (e.target.style.height == '40px') {
                    if (e.target.value.search(/\n/g) == -1) {
                        e.target.style.height = '20px'
                    }
                }
            }
        }
        , checkKeyup: function (e) {
            // console.log('checkKeyup', e)
            if (e.key == 'Shift') {
                this.is_shift = false
            }
        }
        , checkKeydown: function (e) {
            // console.log('checkKeydown', e)
            if (e.key == 'Shift') {
                this.is_shift = true
            }
            if (e.key == 'Enter') {
                if (!this.is_shift && !e.isComposing) {
                    e.preventDefault()
                    this.sendMessage()
                }
            }
        }
        , itemsPush: function () {
            clearTimeout(this.chat_scroll)
            this.chat_scroll = setTimeout(() => {
                if (this.$refs["box-container"]) {
                    this.$refs["box-container"].scrollTo({
                        top: this.$refs["box-container"].scrollHeight,
                        behavior: "smooth"
                    })
                }
            }, 100)

            if (this.$refs.item_chat) {
                this.$refs.item_chat.style.height = 'auto'
            }
        }
    }
    , created() {
        // this.connect()
    }
    , watch: {
        items_list: {
            handler: function () {
                this.itemsPush()
            }
        }
    }
}
</script>

<style>

.bg-chat {
    background-color: #f7f7f7
}

.on {
    background-color: #ddedff;
    margin-left: 10%
}

.on-notice {
    background-color: #fceeef
}

.width-90 {
    width: 90%
}

.font-weight-400 {
    font-weight: 400
}

.word-break-all {
    word-break: break-all;
}


.wrap-chat {
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.wrap-chat-full-screen-mode.wrap-chat {
    position: fixed;
    width: 100%;
    height: 60%;
    left: 0;
    bottom: 0;
    background-color: unset;
}


.slide-fade-enter-active {
    transition: all .5s ease;
}

.slide-fade-leave-active {
    transition: all .0s ease;
}

.slide-fade-enter {
    transform: translateX(30%);

    overflow: hidden;
}

.slide-fade-leave, .slide-fade-leave-to {
    transform: translateY(-10%);
    opacity: 0;
    overflow: hidden;
}
</style>