<template>
	<div class="position-fixed bg-white full-height" style="z-index: 111">
		<div class="header filter">
			<div class="top top_mafi"><!--trans-->
				<div class="util shadow ">
					<h2 class="color-white">{{  $language.live.live_request_modify_title }}</h2>
				</div>
				<div class="save">
					<button
						@click="$emit('closeModify')"
						class="btn_save"
					><v-icon class="color-white">mdi-close</v-icon></button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50 flex-column justify-space-between full-height">
			<!-- 커버등록 -->
			<div class="cartel_cover">
				<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
					<v-icon small class="">mdi-camera</v-icon>
                    {{  $language.live.upload_thumbnail_btn }}
					<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'mobile')" ref="file" file_upload_code_info="CM00700021" />
				</label>
				<div
					v-if="item_live.cartl_chttg_img_url"
					class="cover_background"
				>
					<img
						:src="item_live.cartl_chttg_img_url"
						style="width: 100%"
					/>
				</div>

				<div
					v-else
					class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
				>{{  $language.live.upload_thumbnail }} <br/> <span class="color-gray">* {{  $language.live.thumbnail_size }}</span></div>

			</div>

			<div class="container">
				<div class="subindex_mainbox">

					<!-- 카르텔이름 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelName">{{  $language.live.live_name }}</label>
							<div class="input_utill">
								<input
									v-model="item_live.cartl_chttg_room_name"
									type="text" id="cartelName" :placeholder="$language.live.live_name" maxlength="40"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_live.cartl_chttg_room_name"
											@click="item_live.cartl_chttg_room_name = ''"
											class="active"
										><span class="hide">{{  $language.live.live_name_required }}</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_live.cartl_chttg_room_name.length }}/40</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔이름 -->
                    <!-- 카르텔공지 -->
                    <div class="input_area">
                        <div class="bright_input">
                            <label for="cartelName">{{  $language.live.live_description }}</label>
                            <div class="input_utill">
                                <input
                                    v-model="item_live.cartl_chttg_room_desctn"
                                    type="text" id="cartelName" :placeholder="$language.live.live_description" maxlength="200"
                                >
                                <div class="input_utill_area">
                                    <div class="btn_del">
                                        <!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
                                        <button
                                            v-if="item_live.cartl_chttg_room_desctn"
                                            @click="item_live.cartl_chttg_room_desctn = ''"
                                            class="active"
                                        ><span class="hide">{{  $language.live.live_description }}</span></button>
                                    </div>
                                    <!-- TODO : 텍스트 수 카운트 필요 -->
                                    <!--                                    <span class="input_text_count">{{ item_live.cartl_chttg_room_desctn.length }}/200</span>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //카르텔공지 -->
				</div>
			</div>

			<div
				class="mt-auto btn_area pa-20"
			>
                <button
					@click="modifyCartelLive"
					class="btn_l btn_fill_red"
					:disabled="is_live_request"
				>{{  $language.live.live_request_modify }}</button>
			</div>
		</div>
	</div>
</template>
<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'CartelLiveRequestModify'
	, components: {InputFileNew}
    , props: ['user', 'live_info', 'is_open_modify']
	, data: function(){
		return {
			program: {
				name: 'CartelLiveRequestModify'
				, title: this.$language.cartel.cartel_live_request_modify
				, type: 'mypage'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
					, step: 1
				}
			}
            , show: false
			, item_live: {
				cartl_chttg_room_name: this.live_info.cartl_chttg_room_name
				, stremg_fg: true
				, cartl_number: this.$route.params.idx
				, cartl_chttg_img_url: this.live_info.cartl_chttg_img_url
				, cartl_chttg_room_div_code: 'CA02900001'
			}
		}
	}
	, computed: {
		is_live_request: function(){
			let t = true
			if(this.item_live.cartl_chttg_room_name){
				t = false
			}
			return t
		}
	}
	, methods: {
		setFile: function(e){
			this.$set(this.item_live, 'cartl_chttg_img_url', e)
		}
		, postFile: async function(){
			return true
		}
		, modifyCartelLive: async  function(){
			console.log('modifyCartelLive', this.item_live)

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_live_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_live.cartl_number
							, cartl_chttg_room_number: this.$route.params.live_idx
							, cartl_chttg_room_name: this.item_live.cartl_chttg_room_name
							, cartl_chttg_room_desctn: this.item_live.cartl_chttg_room_desctn
							, cartl_chttg_room_state_code: this.live_info.cartl_chttg_room_state_code
							, stremg_fg: this.live_info.stremg_fg
							, cartl_chttg_img_url: this.item_live.cartl_chttg_img_url
						}
						, name: 'modifyCartelLive'
						, type: true
					})

					if(result.success){
                        console.log(result, '수정완료')
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		document.body.scrollTop = 0
	}
}
</script>

<style>
.box-profile-outline {
	position: absolute; left: calc(50% - 45px); bottom: -45px; z-index: 9;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	background-color: #2C2C2C; width: 95px; height: 95px;
	border-radius: 100%;
	overflow: hidden;
}

.box-profile-inline {
	position: absolute; left: 0; top: 0; z-index: 9;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	width: 95px; height: 95px;
	font-size: 12px;
	border-radius: 100%;
	color: var(--white);
}
.box-profile-outline img {
	object-fit: cover;
}
</style>